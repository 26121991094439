.App{
    width:100vw;
    max-width: 100%;
}
.dashboard-content{
    margin : 0;
    margin-left : 12.5%;
    width:87.3%;
}
@media(max-width : 1700px){
    .dashboard-content{
        margin-left : 18.5%;
        width : 81.5%
    }
}
@media(max-width : 1200px){
    .dashboard-content{
        margin-left : 25.5%;
        width : 74.5%
    }
}
@media(max-width : 1000px){
    .dashboard-content{
        margin-left : 35.5%;
        width : 64.5%
    }
}
@media(max-width : 750px){
    .dashboard-content{
        margin-left : 40.5%;
        width : 59.5%
    }
}
*{
    box-sizing:border-box;
    margin : 0;
    padding:0;
}
